<template>
  <div class="pay-code">
    <div class="top">
      <div class="left">
      <!-- <img src="@/assets/logo.png" alt /> -->
        <span>伊莉丝钻石收银台</span>
      </div>
      <div class="right">
        <span>{{ payInfo.orderno }}</span>
        <span>我的订单</span>
        <span>支付帮助</span>
      </div>
    </div>
    <div class="momey">
      <span>订单提交成功，请尽快付款！订单号：{{ payInfo.orderno }}</span>
      <span>
        应付金额
        <i>{{ payInfo.totalfee | amount }}</i> 元
      </span>
    </div>
    <div class="container">
      <span class="title">微信支付</span>
      <div class="content">
        <div class="wechat-pay">
          <span class="label" v-if="time !== 0">
            距离二维码过期还剩
            <i>{{ time }}</i> 秒，过后请刷新页面重新获取二维码。
          </span>
          <p class="label" v-else>
            <span class="text">二维码已过期, </span>
            <span class="text" @click="refreshTime"> 刷新</span>
            <span class="text">页面重新获取二维码</span>
          </p>
          <div class="qr-code">
            <qrcode :url="payInfo.code_url" :wid="200" :hei="200" :imgwid="53" :imghei="53"></qrcode>
          </div>
          <div class="btn" @click="refreshTime">
            <i class="el-icon-refresh-right"></i>
            <span>刷新付款码</span>
          </div>
        </div>
        <div class="imgbox">
          <img src="../../assets/images/phone1.png" alt />
          <img src="../../assets/images/phone2.png" alt />
        </div>
      </div>
      <div class="select-paytype" @click="goBack">
        <i class="el-icon-arrow-left"></i>
        <span>选择其他支付方式</span>
      </div>
    </div>
    <div class="copyright">COPYRIGHT © 塞维利亚（北京）文化科技有限公司. BY DEMUED</div>
  </div>
</template>
<script>
import qrcode from 'vue_qrcodes'
import { WX_PAY } from "@/service/pay";
import { ORDER_PAYSUCCESS } from "@/service/order";
let timer = null
export default {
  name: "PayCode",
  data() {
    return {
      payInfo: {},
      time: 60,
      show: false
    };
  },

  filters: {
    amount(number) {
      return isNaN(number) ? 0.0 : parseFloat((number / 100).toFixed(2));
    }
  },

  components: {
    qrcode
  },

  created() {
    this.payInfo = JSON.parse(this.$route.query.payInfo)
    console.log(this.payInfo.orderid)
    this.setTimer();
    timer = setInterval(() => {
      ORDER_PAYSUCCESS({type: this.payInfo.type, orderno: this.payInfo.orderno}).then(res => {
        if(res.status === 200) {
          clearInterval(timer)
          this.$router.push({
            path: '/order/finish',
            query: {
              orderno: this.payInfo.orderno,
              paytype: this.payInfo.type,
              orderid: this.payInfo.orderid
            }
          })
        }
      })
    }, 3000)
  },
  destroyed(){
      clearInterval(timer)
  },
  methods: {
    refreshTime() {
      clearTimeout(this.timer);
      this.time = 60;
      this.setTimer();
      WX_PAY({orderid: this.payInfo.orderid, type: this.payInfo.type}).then(res => {
        if(res.status === 200) {
          this.$store.dispatch('order/payInfo', res.attachment)
          this.payInfo = {...res.attachment, orderid: this.payInfo.orderid, type: this.payInfo.type}
        }
        if(res.status === 202) {
          this.$router.push({
            path: '/order/finish',
            query: {
              orderno: this.payInfo.orderno,
              paytype: this.payInfo.type,
              orderid: this.payInfo.orderid
            }
          })
        }
      })
    },
    setTimer() {
      this.timer = setTimeout(() => {
        this.time--;
        if (this.time === 0) {
          clearTimeout(this.timer);
        } else {
          this.setTimer();
        }
      }, 1000);
    },

    goBack() {
      this.$router.go(-1)
    }
  }
};
</script>
<style lang="less" scoped>
.pay-code {
  width: 1580px;
  margin: 0 auto;
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    .left {
      padding-top: 12px;
      display: flex;
      align-items: center;
      img {
        width: 162px;
        margin-right: 20px;
      }
      span {
        color: #666;
        font-size: 26px;
      }
    }
    .right {
      padding: 30px 20px 0 0;
      display: flex;
      align-items: center;
      height: 48px;
      span {
        padding: 0 22px;
        border-right: 1px solid #ccc;
        color: #666;
        font-size: 18px;
        line-height: 1;
      }
      span:last-child {
        border-right: 0;
      }
    }
  }
  .momey {
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    margin-bottom: 90px;
    span:first-child {
      line-height: 1;
      font-size: 20px;
      color: #666;
    }
    span:last-child {
      font-size: 20px;
      color: #666;
      padding-right: 40px;
      i {
        font-size: 24px;
        color: #921d22;
        font-style: normal;
      }
    }
  }
  .container {
    width: 1580px;
    margin: 0 auto 200px;
    box-shadow: 1px 1px 10px #d6d6d6;
    position: relative;
    padding-bottom: 60px;
    .content {
      padding: 0 300px 0 250px;
      display: flex;
      justify-content: space-between;
    }
    .title {
      position: absolute;
      left: 46px;
      top: 24px;
      font-size: 26px;
      color: #666;
    }
    .wechat-pay {
      display: flex;
      flex-direction: column;
      .label {
        line-height: 82px;
        font-size: 20px;
        color: #666;
        white-space: nowrap;
        i {
          color: #921d22;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
        }
        .text {
          color: #921d22;
        }
        .text:nth-child(2) {
          color: blue;
          cursor: pointer;
        }
      }
      .btn {
        width: 472px;
        height: 94px;
        color: #fff;
        background: #921d22;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 30px;
        i {
          margin-right: 28px;
          font-size: 34px;
        }
      }
      .qr-code {
        width: 470px;
        height: 470px;
        margin-bottom: 32px;
        border: 1px solid #cfcfcf;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .imgbox {
      padding: 74px 0 0 150px;
      width: 465px;
      position: relative;
      img:first-child {
        display: block;
        width: 313px;
        height: 623px;
      }
      img:last-child {
        position: absolute;
        left: 0;
        top: 200px;
        width: 342px;
        height: 342px;
      }
    }
    .select-paytype {
      padding-left: 96px;
      margin-top: 90px;
      font-size: 22px;
      color: #666;
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        margin-right: 25px;
        font-size: 28px;
      }
    }
  }
  .copyright {
    margin: 114px 0 200px 170px;
    color: #333;
    font-style: 18px;
  }
}
</style>
